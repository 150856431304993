import React from "react"

import useNav from "../../../hooks/useNav"
import { useTranslation } from "../../../i18n/translate"
import Button from "../../button/button"
import Tile from "../../tile/tile"
import "./studies-and-training-overview.sass"

const StudiesAndTrainingOverview = () => {
  const studiesNav = useNav({ uid: "studies" })
  const trainingNav = useNav({ uid: "training" })

  const { t } = useTranslation()

  return (
    <div className={"_fp-studies-and-training-overview"}>
      <div className={"_fp-studies-and-training-overview__content"}>
        <Tile>
          <h3
            className={
              "_fp-heading-1 _fp-studies-and-training-overview__content__title"
            }
          >
            {t("CAREER-STUDIES_AND_TRAINING-TRAINING-TITLE")}
          </h3>
          <p
            className={
              "_fp-text _fp-studies-and-training-overview__content__copy"
            }
          >
            {t("CAREER-STUDIES_AND_TRAINING-TRAINING-DESCRIPTION")}
          </p>
          {trainingNav && (
            <Button
              buttonSize={"large"}
              bold={true}
              buttonLinkPath={trainingNav?.url}
              buttonStyle={"green"}
              buttonLabel={t("CAREER-STUDIES_AND_TRAINING-TRAINING-CTA")}
            />
          )}
        </Tile>
        <Tile>
          <h3
            className={
              "_fp-heading-1 _fp-studies-and-training-overview__content__title"
            }
          >
            {t("CAREER-STUDIES_AND_TRAINING-STUDIES-TITLE")}
          </h3>
          <p
            className={
              "_fp-text _fp-studies-and-training-overview__content__copy"
            }
          >
            {t("CAREER-STUDIES_AND_TRAINING-STUDIES-DESCRIPTION")}
          </p>
          {studiesNav && (
            <Button
              buttonSize={"large"}
              bold={true}
              buttonLinkPath={studiesNav?.url}
              buttonStyle={"green"}
              buttonLabel={t("CAREER-STUDIES_AND_TRAINING-STUDIES-CTA")}
            />
          )}
        </Tile>
      </div>
    </div>
  )
}

export default StudiesAndTrainingOverview
