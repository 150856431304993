export class CareerContactModel {
  constructor(e) {
    this.id = e.strapiId || e.id
    this.image = e.image
    this.gatsbyImage = e.gatsbyImage
    this.responsibility = e.responsibility
    this.quote = e.quote
    this.name = e.name
    this.phone = e.phone
    this.email = e.email
  }

  static getCareerContactsByNames(careerContacts, names) {
    const matchName = (fullName, firstName, lastName) => {
      let full = (fullName || "").toLowerCase()
      const first = (firstName || "").toLowerCase()
      const last = (lastName || "").toLowerCase()

      if (!full.includes(first)) {
        return false
      }

      full = full.replace(first, "")

      return full.includes(last)
    }

    const filteredContacts = []
    if (careerContacts.length > 0) {
      for (const careerContact of careerContacts) {
        for (const [firstName, lastName] of names) {
          if (matchName(careerContact.name, firstName, lastName)) {
            filteredContacts.push(careerContact)
          }
        }
      }
    }
    return filteredContacts
  }
}
